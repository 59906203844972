// src/components/Register.js

import React, { useState, useContext } from 'react';
import { registerUser, loginUser, getProfile } from './API';
import { AuthContext } from './AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const Register = () => {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormData = location.state?.formData || { username: '', email: '', password: '', bio: '' };
  const [formData, setFormData] = useState(initialFormData);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerUser(formData.username, formData.email, formData.password, formData.bio);
      setMessage('Registration successful!');
      await loginUser(formData.email, formData.password);
      const userData = await getProfile();
      setUser(userData);
      navigate('/profile');
    } catch (error) {
      setMessage(error.message);
    }
  };

  const goToLogin = () => {
    navigate('/login', { state: { formData } });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleSubmit} className="bg-white/60 rounded-xl p-8 w-96">
        <h2 className="text-2xl font-semibold mb-4 text-center">Register</h2>
        {message && <p className="text-red-500 text-center mb-4">{message}</p>}
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Username:</label>
          <input
            type="text"
            required
            value={formData.username}
            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
            className="w-full p-2 border-transparent bg-black/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Email:</label>
          <input
            type="email"
            required
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className="w-full p-2 border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Password:</label>
          <input
            type="password"
            required
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            className="w-full p-2 border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Bio:</label>
          <textarea
            value={formData.bio}
            onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
            className="w-full p-2 border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={3}
            placeholder="Tell us about yourself..."
          />
        </div>
        
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-xl w-full hover:bg-blue-600 transition duration-200">
          Register
        </button>

        <div className="mt-4 text-center">
          <button
            type="button"
            onClick={goToLogin}
            className="text-blue-500 hover:underline"
          >
            Already have an account? Go back to login
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;