import React, { useState, useMemo } from 'react';
import { DummyImage } from '../DummyImage';
import { ChevronDown } from 'lucide-react';
import UserProfileModal from './UserProfileModal';
import './SearchPage.css'; // Import the CSS file for styles


const DonutChart = ({ score, isExpanded }) => {
    const getColor = (score) => {
        if (score >= 80) return '#22c55e';
        if (score >= 70) return '#f59e0b';
        if (score >= 65) return '#fbbf24';
        return '#ef4444';
    };

    const strokeWidth = 5;
    const radius = isExpanded ? 20 : 15;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (score / 100) * circumference;

    return (
        <div className="flex flex-col items-center transition-all duration-300 ease-in-out">
            <svg 
                width={isExpanded ? "50" : "40"} 
                height={isExpanded ? "50" : "40"} 
                className="transform -rotate-90 transition-all duration-300 ease-in-out"
            >
                <circle
                    cx={isExpanded ? "25" : "20"}
                    cy={isExpanded ? "25" : "20"}
                    r={radius}
                    fill="none"
                    stroke="#e5e7eb"
                    strokeWidth={strokeWidth}
                    className="transition-all duration-300 ease-in-out"
                />
                <circle
                    cx={isExpanded ? "25" : "20"}
                    cy={isExpanded ? "25" : "20"}
                    r={radius}
                    fill="none"
                    stroke={getColor(score)}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    className="transition-all duration-300 ease-in-out"
                />
            </svg>
            <span className={`font-semibold mt-1 transition-all duration-300 ease-in-out ${isExpanded ? 'text-base' : 'text-sm'}`}>
                {Math.round(score)}%
            </span>
        </div>
    );
};

const SearchResults = ({ results, isAISearch }) => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showProfileModal, setShowProfileModal] = useState(false);

    const handleClick = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleViewProfile = async (user, e) => {
        e.stopPropagation();
        try {
            // Fetch complete profile data before opening modal
            const response = await fetch(`http://localhost:8000/search/user/${user.id}/profile`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch user profile');
            }
            
            const fullUserData = await response.json();
            setSelectedUser(fullUserData);
            setShowProfileModal(true);
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    const resultsWithScores = useMemo(() => {
        return results.map(result => ({
            ...result,
            matchScore: result.matchScore || Math.floor(Math.random() * 51) + 50
        }));
    }, [results]);

    const sortedResults = useMemo(() => {
        return [...resultsWithScores].sort((a, b) => b.matchScore - a.matchScore);
    }, [resultsWithScores]);

    return (
        <div className="main-content mb-10">
            <div className="mt-4 space-y-4">
                {sortedResults.map((result, index) => (
                    <div 
                        key={index} 
                        className={`rounded-xl p-4 box-search cursor-pointer transition-all duration-300 ease-in-out
                            ${expandedIndex === index && isAISearch ? 'min-h-auto' : ''}`}
                        onClick={() => handleClick(index)}
                    >
                        <div className="flex items-center justify-between transition-all duration-300 ease-in-out">
                            <div className="flex items-center space-x-4 transition-all duration-300 ease-in-out">
                            

                                {result.picture ? (
                                    <img
                                        src={result.picture}
                                        alt={result.username}
                                        className=" rounded-lg object-cover w-12 h-12 transition-all duration-300 ease-in-out "
                                    />
                                ) : (
                                    <DummyImage 
                                        width={48} 
                                        height={48} 
                                        shape="avatar" 
                                        className="rounded-lg transition-all duration-300 ease-in-out"
                                    />
                                )}
                                                                   

                                <div className="flex flex-col justify-center transition-all duration-300 ease-in-out">
                                

                                    <div className="flex items-center gap-2">
                                        <h3 className="font-semibold text-gray-900 text-lg text-left transition-all duration-300 ease-in-out">
                                            {result.username}
                                        </h3>
                                        {expandedIndex === index && (<button
                                            onClick={(e) => handleViewProfile(result, e)}
                                            className="px-2 py-1 bg-white/80 text-black rounded-lg text-xs hover:bg-white transition-all duration-300 ease-in-out hover:scale-105"
                                        >
                                            View Profile
                                        </button> )}
                                    </div>
                                    
                                    <p className={`text-sm text-gray-600 text-left line-clamp-2 transition-all duration-300 ease-in-out ${expandedIndex === index ? 'line-clamp-none' : ''}`}>
                                        {result.bio || 'No bio available'}
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-center space-x-4 transition-all duration-300 ease-in-out">
                                <DonutChart 
                                    score={result.matchScore}
                                    isExpanded={window.innerWidth >= 768 ? expandedIndex === index : false}
                                />
                                <ChevronDown 
                                    className={`w-5 h-5 transition-transform duration-300 ease-in-out
                                        ${expandedIndex === index ? 'rotate-180' : 'rotate-0'}`}
                                />
                            </div>
                        </div>

                            






                        {isAISearch && expandedIndex === index && result.summary && (

                            
                            <div className="mt-4 transition-all duration-300 ease-in-out">

                            <div className="mt-4 pt-4 border-t border-black/20 transition-all duration-300 ease-in-out">
                            </div>
                                <h4 className="font-semibold text-gray-900">Why connect with {result.username}?</h4>
                                <ul className="text-sm text-gray-600 text-left mt-2 list-disc list-inside">
                                    {result.summary.split('\n').map((line, idx) => (
                                        <li key={idx} className="transition-all duration-300 ease-in-out">{line.replace('•', '').trim()}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        

                        {expandedIndex === index && result.matchingFields && result.matchingFields.length > 0 && (
                            <div className={`flex flex-wrap gap-2 transition-all duration-300 ease-in-out mt-4`}>
                                                                <div className="w-full text-sm text-black  text-left">Identified based on:</div>

                                {result.matchingFields.map((field, idx) => (
                                    <div 
                                        key={idx} 
                                        className={`bg-white/80 text-black/80 rounded-full px-3 py-0.5 text-[10px] transition-all duration-300 ease-in-out`}
                                    >
                                        {field}
                                    </div>
                                ))}
                            </div>
                        )}

                        
                    </div>
                ))}
            </div>

            {/* Profile Modal */}
            {showProfileModal && selectedUser && (
                <UserProfileModal 
                    user={selectedUser}
                    onClose={() => {
                        setShowProfileModal(false);
                        setSelectedUser(null);
                    }}
                />
            )}
        </div>
    );
};

export default SearchResults;