import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../index.css';
import { ArrowRight } from 'lucide-react'; // Import the ArrowRight icon
import { validateName, getFunnyNameOrigin } from './API'; // Adjust the path if necessary

const First = () => {
  const numberWords = ["Don't click me  or my grandma will DIE!", 
                        "ok good, thought you were a", 
                        "human", 
                        "looking for", 
                        "another social app", 
                        "to show off", 
                        "and get addicted", 
                      
                        "Oh you are a human?", 
                        "yeah me too...", 
                        "I'm Gabor", 
                        "Whats your name?", 
                        "Okay {userName}, here is the deal",
                        "I recently organized a",
                        
                        "dinner", 
                      
                     
                        "I thought",
                        
                        "you know what would be cool?", 
                        
                        "I thought it would be cool",
                        "to connect my friends",


                    
                        "Who would get along the most?", 
                        "Who would benefit from each other?", 
                     
                        "So I ran a test", 
                        "I organized THE dinner", 
                        "and invited",
                        "the best people in Zurich", 
                        "my best friends ", 

                        "the evening came",

                      
                        "Anyway it was GREAT", 
                        "and we all lived happily ever after...", 
                        "Ciao!",

                        "You're still here?", 
                        "Yeah mee too...",

                        "writing this:", 

                        "YOU SHOULD MEET", 
                        "YOU SHOULD COLLABORATE", 
                        "YOU SHOULD HELP", 
                        "your friends friends",

                        "your colleagues colleagues", 
                        "your networks network",
                       


                        "and", 
                        "I want to automate this", 

                        "with good math and some code",

                        

                        
                        "O.K. dont want to waste your time here",
                        "Last clear instructions",
                        "1. Register",
                        "2. Fill in as much info as possible",
                        "as much asyou feel comfortable with",
                        "You can decide if public or hidden",
                        "fill in five things you are looking for",
                        "fill in five things you can contribute/give/help with",


                        
                        "3. Add people as friends, who you met at least once",
                       
                        "4. Search",
                        "This is the first draft here, so...",
                        "The search feature is really shitty right now",
                        "will improve and add more features soon",
                        "The idea is, that you can search for",

                        "experiences",
                        "wisdoms & discussions",
                        "opinions & ideas",
                        "skills & interests",
                        "collaborators",
                        "help, locations, mentors, creativity ... anything",

                        "Upcoming features:",
                        "Suggest friends to meet",
                        "Link strength to friends for better reccommendations",
                        "have a feed, where you can swipe to help others",
                        "I have a lot more ideas",
                        "Please keep this link for yourself",
                        "Thanks for you time!",
                        "It would be nice if you could fill this out in the next days",
                        "It takes between 5 min and 5 h ",
                        "Love you!",
                        "Thank you!",

                    ];
                    
                    
                    
                    const [currentIndex, setCurrentIndex] = useState(0);
                    const [isVisible, setIsVisible] = useState(false);
                    const [isComplete, setIsComplete] = useState(false);
                    const [userName, setUserName] = useState('');
                    const [showInput, setShowInput] = useState(false);
                    const [errorMessage, setErrorMessage] = useState('');
                    const [funnyComment, setFunnyComment] = useState('');
                    const navigate = useNavigate();
                  
                    useEffect(() => {
                      const timer = setTimeout(() => {
                        setIsVisible(true);
                        if (numberWords[currentIndex] === "Whats your name?") {
                          setShowInput(true);
                        }
                      }, 1000);
                  
                      return () => clearTimeout(timer); // Cleanup the timer on component unmount
                    }, [currentIndex, numberWords]);
                  
                    const handleTextClick = async () => {
                      if (showInput) {
                        if (!userName.trim()) {
                          setErrorMessage('Please enter your name.');
                        } else {
                          setErrorMessage('');
                          try {
                            const validationResponse = await validateName(userName);
                            if (!validationResponse.is_valid_name) {
                              setErrorMessage("Hmm, that doesn't seem like a real name!");
                            } else {
                              const funnyResponse = await getFunnyNameOrigin(userName);
                              setFunnyComment(funnyResponse.funny_comment);
                              setShowInput(false);
                              setIsVisible(false);
                              setTimeout(() => {
                                setIsVisible(true);
                              }, 0);
                            }
                          } catch (error) {
                            console.error('Error during name validation or getting funny comment:', error);
                            setErrorMessage('An error occurred. Please try again.');
                          }
                        }
                      } else if (funnyComment) {
                        setFunnyComment('');
                        setIsVisible(false);
                        setTimeout(() => {
                          const nextIndex = currentIndex + 1;
                          if (nextIndex >= numberWords.length) {
                            setIsComplete(true);
                          } else {
                            setCurrentIndex(nextIndex);
                            setIsVisible(true);
                          }
                        }, 0);
                      } else {
                        setIsVisible(false);
                        setTimeout(() => {
                          const nextIndex = currentIndex + 1;
                          if (nextIndex >= numberWords.length) {
                            setIsComplete(true);
                          } else {
                            setCurrentIndex(nextIndex);
                            setIsVisible(true);
                          }
                        }, 0);
                      }
                    };
                  
                    // Guard against out-of-bounds access
                    let displayText = numberWords[currentIndex] || '';
                  
                    if (displayText.includes('{userName}')) {
                      displayText = displayText.replace('{userName}', userName || 'Person');
                    }
                  
                    if (errorMessage) {
                      displayText = errorMessage;
                    }
                  
                    return (
                      <div className="h-screen flex flex-col items-center justify-center select-none">
                        {!isComplete ? (
                          <div className="text-center">
                            <h2
                              className={`text-lg font-bold text-black cursor-pointer select-none ${
                                currentIndex === 0 ? 'transition-opacity duration-1000' : ''
                              } ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                              onClick={handleTextClick}
                            >
                              {funnyComment ? funnyComment : displayText}
                            </h2>
                            {showInput && (
                              <input
                                type="text"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                placeholder="type your name..."
                                className={`mt-2 border-b border-black/40 bg-transparent focus:outline-none transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                              />
                            )}
                            {errorMessage && (
                              <p className="text-red-500 mt-2 select-none">{errorMessage}</p>
                            )}
                          </div>
                        ) : (
                          <button
                            onClick={() => navigate('/login')}
                            className="flex items-center text-lg font-bold text-black py-2 px-4 hover:hunderline transition-all duration-200 select-none"
                          >
                            Register <ArrowRight className="ml-2" />
                          </button>
                        )}
                      </div>
                    );
                  };
                  
                  export default First;
                  