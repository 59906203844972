// src/components/Search/SearchPage.js

import React, { useState } from 'react';
import { searchDatabase, keywordSearchDatabase } from './SearchAPI';
import SearchResults from './SearchResults';
import './SearchPage.css'; // Import the CSS file for styles

const SearchPage = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isAISearch, setIsAISearch] = useState(false);
    const [NoResultsShow, setNoResultsShow] = useState(false); // New state for tracking search in progress

    const handleInputChange = (e) => {
        setQuery(e.target.value);
        setNoResultsShow(false); // Reset results when input changes
    };

    const handleSearch = async (e, useLLM = true) => {
        e.preventDefault();

        if (!query.trim()) {
            setResults([]);
            return;
        }

        setIsLoading(true);
        setError(null);
        setIsAISearch(useLLM);

        try {
            const searchResults = await searchDatabase(query, useLLM);
            setResults(searchResults.results);
        } catch (err) {
            setError(err.message);
            setResults([]);
        } finally {
            setIsLoading(false);
            setNoResultsShow(true); 
        }
    };

    return (
        <div className="flex justify-center items-start">
            <div className="mt-40" style={{ width: '90%', maxWidth: '800px' }}>
                <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center text-black">Search Your Network</h1>

                {/* Search Input and Buttons */}
                <div className="flex flex-col items-center md:space-x-4 mb-4">
                    <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        placeholder="Search..."
                        className="w-[95%] p-2 pl-4 rounded-lg box-search placeholder-black/50 text-black text-sm md:text-base focus:outline-none"
                    />
                    <div className="flex justify-center space-x-2 md:whitespace-nowrap">
                        <button
                            onClick={(e) => handleSearch(e, true)}
                            className={`w-28 p-2 rounded-lg text-xs transition-all duration-300 ease-in-out ${
                              isLoading 
                                ? 'bg-black/5 text-black/30 cursor-not-allowed'
                                : 'bg-white/50 text-black hover:bg-white hover:text-black hover:scale-105'
                            }`}
                            disabled={isLoading}
                        >
                            AI Search
                        </button>
                        <button
                            onClick={(e) => handleSearch(e, false)}
                            className={`w-28 p-2 rounded-lg text-xs transition-all duration-300 ease-in-out ${
                              isLoading 
                                ? 'bg-black/5 text-black/30 cursor-not-allowed'
                                : 'bg-white/50 text-black hover:bg-white hover:text-black hover:scale-105'
                            }`}
                            disabled={isLoading}
                        >
                            Keyword Search
                        </button>
                    </div>
                </div>

                {/* Error Message */}
                {error && (
                    <div className="mt-4 p-3 md:p-4 bg-red-100 text-red-700 rounded-lg text-sm md:text-base">
                        {error}
                    </div>
                )}

                {/* Search Results */}
                <div className="w-[95%] flex text-center mx-auto mt-8 items-center justify-center">
                    {isLoading ? (
                        <div>
                            <span className="searching-text">Searching<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span>
                        </div>
                    ) : results.length > 0 ? (
                        <SearchResults results={results} isAISearch={isAISearch} />
                    ) : (
                        query && !isLoading && results.length === 0 && NoResultsShow && (
                            <p className="text-white text-sm md:text-base">No results found for "{query}".</p>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchPage;
