import React, { useState, useContext, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import '../index.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  // Handle clicks outside the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className="md:bg-gray-500 md:bg-opacity-20 p-4 relative">
      <div className="container mx-auto flex justify-between items-center">
        

{/* Desktop Navbar */}
<div className="desktop-navbar">
          <NavLink
            to="/"
            end
            className={({ isActive }) =>
              `nav-button select-none ${isActive ? 'active' : ''}`
            }
          >
            Home
          </NavLink>

          {user ? (
            <>
              <NavLink
                to="/profile"
                className={({ isActive }) =>
                  `nav-button select-none ${isActive ? 'active' : ''}`
                }
              >
                Profile
              </NavLink>

              <NavLink
            to="/search"
            className={({ isActive }) =>
              `nav-button select-none ${isActive ? 'active' : ''}`
            }
          >
            Search
          </NavLink>


              <button
                onClick={handleLogout}
                className="nav-button select-none"
              >
                Logout
              </button>
            </>
          ) : (
            <NavLink
              to="/login"
              className={({ isActive }) =>
                `nav-button select-none ${isActive ? 'active' : ''}`
              }
            >
              Login
            </NavLink>
          )}

       
        </div>



        {/* Hamburger Menu Button */}
        <button
          ref={buttonRef}
          onClick={() => setIsOpen(!isOpen)}
          className="hamburger-button select-none"
          aria-label="Toggle navigation menu"
        >
          <svg
            className="w-6 h-6 text-black"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            )}
          </svg>
        </button>

        {/* Overlay */}
        {isOpen && <div className="overlay visible" onClick={() => setIsOpen(false)}></div>}


        {/* Navigation Links (Sidebar) */}
        <div
          ref={sidebarRef}
          className={`sidebar text-center ${isOpen ? 'open' : ''}`}
        >
          <NavLink
            to="/"
            end
            className={({ isActive }) =>
              `nav-button select-none ${isActive ? 'font-bold text-[13px]' : ''}`
            }
            onClick={() => setIsOpen(false)}
          >
            Home
          </NavLink>

          {user ? (
            <>
              <NavLink
                to="/profile"
                className={({ isActive }) =>
                  `nav-button select-none ${isActive ? 'font-bold text-[13px]' : ''}`
                }
                onClick={() => setIsOpen(false)}
              >
                Profile
              </NavLink>
             

              <NavLink
            to="/search"
            className={({ isActive }) =>
              `nav-button select-none ${isActive ? 'font-bold text-[13px]' : ''}`
            }
            onClick={() => setIsOpen(false)}
          >
            Search
          </NavLink>

          <button
                onClick={() => {
                  handleLogout();
                  setIsOpen(false);
                }}
                className="nav-button select-none"
              >
                Logout
              </button>

          
            </>
          ) : (
            <NavLink
              to="/login"
              className={({ isActive }) =>
                `nav-button select-none ${isActive ? 'font-bold text-[13px]' : ''}`
              }
              onClick={() => setIsOpen(false)}
            >
              Login
            </NavLink>
          )}

         
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
