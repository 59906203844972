// src/components/Login.js

import React, { useState, useContext } from 'react';
import { loginUser, registerUser, getProfile } from './API';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import '../index.css';

const Login = () => {
  const { setUser } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    bio: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      if (isLogin) {
        await loginUser(formData.email, formData.password);
        const userData = await getProfile();
        setUser(userData);
        setMessage('Login successful!');
        navigate('/profile');
      } else {
        if (formData.password !== formData.confirmPassword) {
          setMessage('Passwords do not match.');
          return;
        }
        await registerUser(formData.username, formData.email, formData.password, formData.bio);
        setMessage('Registration successful! Logging in...');
        
        // Automatically log in the user after successful registration
        await loginUser(formData.email, formData.password);
        const userData = await getProfile();
        setUser(userData);
        navigate('/profile');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.message);
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setMessage('');
    setFormData({
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      bio: '',
    });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleSubmit} className="box-login rounded-lg p-8 w-96">
        <h2 className="text-2xl font-semibold mb-4 text-center">{isLogin ? 'Login' : 'Register'}</h2>
        {message && (
          <p className={`text-center mb-4 ${message.includes('Registration successful!') ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </p>
        )}

        <div className="flex flex-col space-y-4">
          {!isLogin && (
            <div>
              <label className="block text-black mb-1">Full Name:</label>
              <input
                type="text"
                required
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                className="w-full border-b-[1px] border-black/10  bg-transparent focus:outline-none "
                
              />
            </div>
          )}

          <div>
            <label className="block text-black">Email:</label>
            <input
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="w-full border-b-[1px] border-black/10 bg-transparent focus:outline-none "
            />
          </div>

          <div>
            <label className="block text-black">Password:</label>
            <input
              type="password"
              required
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              className="w-full border-b-[1px] border-black/10 bg-transparent focus:outline-none "
            />
          </div>

          {!isLogin && (
            <>
              <div>
                <label className="block text-black ">Confirm Password:</label>
                <input
                  type="password"
                  required
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                  className="w-full border-b-[1px] border-black/10 bg-transparent focus:outline-none "
                />
              </div>

              {/* <div>
                <label className="block text-gray-700">Bio:</label>
                <textarea
                  value={formData.bio}
                  onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
                  className="w-full p-2 border-b-2 border-gray-300 focus:outline-none "
                  rows={3}
                  placeholder="Tell us about yourself..."
                />
              </div> */}
            </>
          )}
        </div>

        <button type="submit" className=" mt-8 text-black font-bold px-4 py-2 rounded-lg w-full hover:underline transition duration-200 mt-4">
          {isLogin ? 'Login' : 'Register'}
        </button>

        <div className=" text-center">
          <button
            type="button"
            onClick={toggleForm}
            className="text-black hover:underline"
          >
            {isLogin ? 'Need an account? Register' : 'Already have an account? Login'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
