// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="container mx-auto px-4 py-8 text-center">
    <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
    <p className="text-lg mb-6">Sorry, the page you are looking for does not exist.</p>
    <Link to="/" className="text-pink-500 hover:underline">
      Go back to Home
    </Link>
  </div>
);

export default NotFound;
