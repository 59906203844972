// src/components/Search/UserProfileModal.js
import React from 'react';
import { FileText, ChevronDown } from 'lucide-react';
import { DummyImage } from '../DummyImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadUserPDF } from '../API';
import { FaLinkedin, FaGithub, FaTwitter, FaExternalLinkSquareAlt } from 'react-icons/fa';
import { PiLinkFill } from "react-icons/pi";
import { MdEmail } from "react-icons/md";
import { FaFileAlt } from 'react-icons/fa';



const UserProfileModal = ({ user, onClose }) => {
    const handleDownloadPDF = async () => {
        try {
            await downloadUserPDF(user.id);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 py-10">
            <div className="w-full max-w-4xl bg-[rgb(240,234,219)] rounded-lg shadow-lg p-6 relative mx-4 max-h-[80%] overflow-y-auto">
                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 px-3 py-1 bg-white/70 text-black rounded-lg text-xs hover:bg-white transition-transform duration-300 ease-in-out"
                >
                    <FontAwesomeIcon icon={faTimes} className="mr-1" />
                    Close
                </button>

                <div className="mt-8">
                    {/* User Information Section */}
                    <div className="rounded-lg w-full bg-black/10 p-6 mb-6 text-left">
                        <h2 className="text-2xl font-semibold mb-4">{user.username}'s Profile</h2>
                        
                        <div className="flex flex-col md:flex-row items-start gap-6">
                            <div className="relative">
                                {user.picture ? (
                                    <img 
                                        src={user.picture} 
                                        alt="Profile Picture"
                                        className="w-40 h-40 rounded-lg  object-cover" 
                                    />
                                ) : (
                                    <DummyImage shape="avatar" className="mb-4 w-40 h-40 rounded-lg" />
                                )}
                            </div>

                            <div className="flex flex-col">
                                {/* <p className="text-base mb-2"><strong>Email:</strong> {user.email}</p> */}
                                <p className="text-base mb-2"><strong>Size of 1st Network:</strong> {user.firstnetworkSize || 'N/A'}</p>
                                <p className="text-base mb-2"><strong>Size of 2nd Network:</strong> {user.secondnetworkSize || 'N/A'}</p>
                                <p className="text-base mb-2"><strong>You're based in:</strong> {user.location || 'N/A'}</p>
                                <div className="flex space-x-2 mt-2">
                                    {/* <button 
                                        onClick={handleDownloadPDF}
                                        className="bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out"
                                    >
                                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                        Download CV
                                    </button> */}

                                    <FaLinkedin className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                    <FaTwitter className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                    <FaGithub className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                    <PiLinkFill className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                    <FaExternalLinkSquareAlt className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />

                                    {user.linkedin && (
                                        <a href={user.linkedin} target="_blank" rel="noopener noreferrer" className="text-white">
                                            <FaLinkedin className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                        </a>
                                    )}
                                    {user.twitter && (
                                        <a href={user.twitter} target="_blank" rel="noopener noreferrer" className="text-white">
                                            <FaTwitter className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                        </a>
                                    )}
                                    {user.github && (
                                        <a href={user.github} target="_blank" rel="noopener noreferrer" className="text-white">
                                            <FaGithub className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                        </a>
                                    )}
                                    {user.website && (
                                        <a href={user.website} target="_blank" rel="noopener noreferrer" className="text-white">
                                            <PiLinkFill className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                        </a>
                                    )}
                                     {user.website && (
                                        <a href={user.website} target="_blank" rel="noopener noreferrer" className="text-white">
                                            <FaExternalLinkSquareAlt className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                        </a>
                                    )}

                                    {user.pdf_filename && (
                                     <a onClick={handleDownloadPDF} className="relative cursor-pointer flex flex-col items-center justify-center">
                                         <FaFileAlt className="w-6 h-6 hover:scale-110 transition-transform" />
                                         <span className="relative text-sm font-bold text-orange-400">CV</span>
                                     </a>
                                    )}


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Bio Section */}
                    {user.bio && (
                        <div className="rounded-lg w-full bg-black/10 p-6 mb-6">
                            <h2 className="text-2xl font-semibold text-left">Bio</h2>
                            <div className="w-full rounded-lg bg-transparent pt-2  text-black  text-left">
                                {user.bio}
                            </div>
                        </div>
                    )}

                    {/* CV Section */}
                    {/* {user.pdf_filename && (
                        <div className="rounded-lg w-full bg-black/10 p-6 mb-6">
                            <h2 className="text-2xl font-semibold mb-4 text-left">CV</h2>
                            <div className="hidden md:flex flex-col gap-2 p-4 border-2 border-dashed border-gray-500 rounded-lg group w-[20%] min-w-[15rem]">
                                <div className="flex items-center gap-2">
                                    <FileText size={32} className="text-gray-900 " />
                                    <span className="text-gray-600 text-left">{user.pdf_filename}</span>
                                </div>
                                <button 
                                    onClick={handleDownloadPDF}
                                    className="bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out"
                                >
                                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                    Download CV
                                </button>
                            </div>

                            <div className="flex md:hidden flex-col gap-2 p-4 border-2 border-dashed border-gray-500 rounded-lg transition-transform transform hover:scale-105 hover:border-blue-500 hover:text-blue-500 transition-all duration-300 ease-in-out group w-[20%] min-w-[40rem]">
                                <div className="flex flex-col items-start gap-2">
                                    <FileText size={32} className="text-gray-900 group-hover:text-blue-500 transition-all duration-300 ease-in-out" />
                                    <span className="text-gray-600 break-words text-left">{user.pdf_filename}</span>
                                </div>
                                <button 
                                    onClick={handleDownloadPDF}
                                    className="bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out w-full"
                                >
                                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                    Download CV
                                </button>
                            </div>
                        </div>
                    )} */}

                    {/* Public Custom Fields Section */}
                    <div className="rounded-lg w-full bg-black/10 p-6 mb-6">
                        <h2 className="text-2xl font-semibold mb-4 text-left">Q&A</h2>
                        {user.custom_fields && user.custom_fields.length > 0 ? (
                            user.custom_fields
                                .filter(field => field.isPublic)
                                .map((field, index) => (
                                    <div key={index} className="mt-6 w-full">
                                        <div className="mb-2">
                                            <h3 className="font-semibold text-lg text-left">{field.title}</h3>
                                        </div>
                                        <div className="w-full rounded-lg bg-transparent pt-2 pb-2 text-black hover:bg-white transition-all duration-300 ease-in-out text-left">
                                            {field.text}
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <p className="text-sm text-black text-left">No additional information available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfileModal;
