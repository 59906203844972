// src/components/API.js
import axios from 'axios';

//const base = 'http://172.161.87.201:8000'; // Ensure this matches your backend URL, 'http://localhost:8000, http://172.161.87.201:8000
//const base = 'http://gabors.net:8000';
const base = '/api';

//const base = 'http://localhost:8000'; // Ensure this matches your backend URL, 'http://localhost:8000, http://172.161.87.201:8000



// Register User
const registerUser = async (username, email, password, bio) => {
  const response = await fetch(`${base}/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, email, password, bio }),
  });
  const data = await response.json();

  if (!response.ok) {
    console.error('Error data:', data); // Log the error data for debugging
    let errorMessage = 'Registration failed';
    if (data.detail) {
      if (Array.isArray(data.detail)) {
        // Extract messages from the array
        errorMessage = data.detail.map((err) => `${err.msg}`).join(', ');
      } else if (typeof data.detail === 'string') {
        errorMessage = data.detail;
      }
    }
    throw new Error(errorMessage);
  }
  return data;
};

// Login User
const loginUser = async (email, password) => {
  const response = await fetch(`${base}/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();

  if (!response.ok) {
    console.error('Error data:', data); // Log the error data for debugging
    let errorMessage = 'Login failed';
    if (data.detail) {
      if (Array.isArray(data.detail)) {
        errorMessage = data.detail.map((err) => `${err.msg}`).join(', ');
      } else if (typeof data.detail === 'string') {
        errorMessage = data.detail;
      }
    }
    throw new Error(errorMessage);
  }
  localStorage.setItem('token', data.access_token);
  return data;
};

// Get Profile
const getProfile = async () => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${base}/profile`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.detail || 'Failed to fetch profile');
  }
  return data;
};

// Update Profile
const updateProfile = async (updates) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${base}/profile`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updates),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.detail || 'Failed to update profile');
  }
  return data;
};

// Download PDF
const downloadPDF = async () => {
  const token = localStorage.getItem('token');
  try {
    console.log("Attempting to download PDF...");
    const response = await fetch(`${base}/download_pdf`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to download PDF');
    }

    // Create a Blob from the response
    const blob = await response.blob();
    console.log("PDF Blob received.");

    // Create a link element, hide it, direct it towards the Blob, and trigger a click
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    // Get the filename from the response headers, or use a default name
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'downloaded.pdf';
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match && match.length > 1) {
        filename = match[1];
      }
    }
    console.log(`PDF filename: ${filename}`);

    link.setAttribute('download', filename);  // Set the file name
    document.body.appendChild(link);
    link.click();  // Trigger the download
    document.body.removeChild(link);  // Clean up

    // Release the object URL
    window.URL.revokeObjectURL(url);
    console.log("PDF download triggered successfully.");
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw new Error('Error downloading PDF');
  }
};

// Update Bio
const updateBio = async (bio) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/update_bio`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ bio }),  // Send the bio as a JSON object
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to update bio.');
    }

    console.log("Bio updated successfully.");
    return result;
  } catch (error) {
    console.error('Error updating bio:', error);
    throw new Error('Error updating bio.');
  }
};

// Check PDF
const checkPDF = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/check_pdf`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to check for PDF.');
    }

    return result;  // result will have { has_pdf: true/false, pdf_filename: '' }
  } catch (error) {
    console.error('Error checking PDF:', error);
    throw new Error('Error checking PDF.');
  }
};

// Delete PDF
const deletePDF = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/delete_pdf`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete PDF.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error deleting PDF:', error);
    throw error;
  }
};

// Update Custom Fields
const updateCustomFields = async (customFields) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/update_custom_fields`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ custom_fields: customFields }),
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to update custom fields.');
    }

    console.log("Custom fields updated successfully.");
    return result;
  } catch (error) {
    console.error('Error updating custom fields:', error);
    throw new Error('Error updating custom fields.');
  }
};

// src/components/API.js

// Delete Custom Field
const deleteCustomField = async (fieldTitle) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/delete_custom_field/${encodeURIComponent(fieldTitle)}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to delete custom field.');
    }

    console.log(`Custom field '${fieldTitle}' deleted successfully.`);
    return result;
  } catch (error) {
    console.error('Error deleting custom field:', error);
    throw new Error('Error deleting custom field.');
  }
};



// --------- Friend Management API Functions ---------

const searchUsers = async (query) => {
    try {
        const response = await axios.get(`${base}/search_users`, {
            params: { query },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data.users;
    } catch (error) {
        console.error('Error searching users:', error);
        throw error.response?.data || new Error('Failed to search users.');
    }
};

// Send Friend Request
const sendFriendRequest = async (toUserId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/send_friend_request/${encodeURIComponent(toUserId)}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to send friend request.');
    }

    return result.message;
  } catch (error) {
    console.error('Error sending friend request:', error);
    throw new Error('Error sending friend request.');
  }
};

// Accept Friend Request
const acceptFriendRequest = async (fromUserId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/accept_friend_request/${encodeURIComponent(fromUserId)}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to accept friend request.');
    }

    return result.message;
  } catch (error) {
    console.error('Error accepting friend request:', error);
    throw new Error('Error accepting friend request.');
  }
};

// Decline Friend Request
const declineFriendRequest = async (fromUserId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/decline_friend_request/${encodeURIComponent(fromUserId)}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to decline friend request.');
    }

    return result.message;
  } catch (error) {
    console.error('Error declining friend request:', error);
    throw new Error('Error declining friend request.');
  }
};

// Remove Friend
const removeFriend = async (friendId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/remove_friend/${encodeURIComponent(friendId)}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to remove friend.');
    }

    return result.message;
  } catch (error) {
    console.error('Error removing friend:', error);
    throw new Error('Error removing friend.');
  }
};

// Delete Sent Friend Request
const deleteFriendRequest = async (toUserId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/delete_friend_request/${encodeURIComponent(toUserId)}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to delete friend request.');
    }

    return result.message;
  } catch (error) {
    console.error('Error deleting friend request:', error);
    throw new Error('Error deleting friend request.');
  }
};

// Get Friends List
const getFriendsList = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${base}/get_friends_list`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        console.error('Backend Error:', result.detail);
        throw new Error(result.detail || 'Failed to fetch friends list.');
      }
      return result.friends; // Array of friend objects
    } catch (error) {
      console.error('Error fetching friends list:', error);
      throw new Error('Error fetching friends list.');
    }
  };
  

// Get Incoming Friend Requests
const getFriendRequests = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/get_friend_requests`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to fetch friend requests.');
    }
    return result.requests; // Array of incoming friend requests
  } catch (error) {
    console.error('Error fetching friend requests:', error);
    throw new Error('Error fetching friend requests.');
  }
};

// Get Outgoing Friend Requests
const getSentRequests = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/get_sent_requests`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to fetch sent friend requests.');
    }
    return result.sent_requests; // Array of outgoing friend requests
  } catch (error) {
    console.error('Error fetching sent friend requests:', error);
    throw new Error('Error fetching sent friend requests.');
  }
};

// Get Users by IDs
const getUsers = async (userIds) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${base}/get_users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user_ids: userIds }),
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.detail || 'Failed to fetch users.');
    }

    return result.users; // Array of user objects
  } catch (error) {
    console.error('Error fetching users:', error);
    throw new Error('Error fetching users.');
  }
};



// Upload Picture
const uploadPicture = async (file) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await fetch(`${base}/upload_picture`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.detail || 'Failed to upload picture.');
      }
  
      console.log("Picture uploaded successfully.");
      return result;
    } catch (error) {
      console.error('Error uploading picture:', error);
      throw new Error('Error uploading picture.');
    }
  };
  
  // Delete Picture
  const deletePicture = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${base}/delete_picture`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.detail || 'Failed to delete picture.');
      }
  
      console.log("Picture deleted successfully.");
      return result;
    } catch (error) {
      console.error('Error deleting picture:', error);
      throw new Error('Error deleting picture.');
    }
  };
  
  // Open PDF in a new tab
  const openPDFInNewTab = async () => {
    const token = localStorage.getItem('token');
    try {
      console.log("Attempting to open PDF...");
      const response = await fetch(`${base}/download_pdf`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to open PDF');
      }

      // Create a Blob from the response
      const blob = await response.blob();
      console.log("PDF Blob received.");

      // Create a URL for the Blob and open it in a new tab
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');

      // Release the object URL
      window.URL.revokeObjectURL(url);
      console.log("PDF opened in a new tab successfully.");
    } catch (error) {
      console.error('Error opening PDF:', error);
      throw new Error('Error opening PDF');
    }
  };




  // src/components/API.js

export const getUserProfile = async (userId) => {
  try {
      const response = await fetch(`${base}/search/user/${userId}/profile`, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
      });
      
      if (!response.ok) {
          throw new Error('Failed to fetch user profile');
      }

      const userData = await response.json();

      // Filter out non-public custom fields (if not handled by backend)
      userData.custom_fields = userData.custom_fields.filter(field => field.isPublic);

      return userData;
  } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
  }
};


export const downloadUserPDF = async (userId) => {
    try {
        const response = await fetch(`${base}/search/user/${userId}/download_pdf`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to download PDF');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank');
        if (newTab) {
            newTab.focus();
        } else {
            throw new Error('Failed to open new tab');
        }
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading PDF:', error);
        throw error;
    }
};





// Validate Name
export const validateName = async (name) => {
  try {
    const response = await axios.post(
      `${base}/validate_name`,
      { name }
      // No need for headers here since authentication is not required
    );
    return response.data;
  } catch (error) {
    console.error('Error validating name:', error);
    throw error.response?.data || new Error('Failed to validate name.');
  }
};

// Get Funny Name Origin
export const getFunnyNameOrigin = async (name) => {
  try {
    const response = await axios.post(
      `${base}/funny_name_origin`,
      { name }
      // No need for headers here since authentication is not required
    );
    return response.data;
  } catch (error) {
    console.error('Error getting funny name origin:', error);
    throw error.response?.data || new Error('Failed to get funny name origin.');
  }
};



 

  
  // Export all functions
  export {
    registerUser,
    loginUser,
    getProfile,
    updateProfile,
    downloadPDF,
    updateBio,
    checkPDF,
    deletePDF,
    updateCustomFields,
    deleteCustomField,
    // Friend Management
    searchUsers,
    sendFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    removeFriend,
    deleteFriendRequest,
    getFriendsList,
    getFriendRequests,
    getSentRequests,
    getUsers,
    uploadPicture,    // New Export
    deletePicture,    // New Export
    openPDFInNewTab, // New Export
  
  };
  
