// src/App.js

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { AuthProvider } from './components/AuthContext';

import './index.css'; // Tailwind CSS and custom styles

function App() {
  const location = useLocation();
  const isHomePage = location.pathname === '/' || location.pathname === '/First' || location.pathname === '/login';

  return (
    <AuthProvider>
      <div className="flex flex-col min-h-screen">
        {/* Conditionally render NavBar based on the current path */}
        {!isHomePage && <NavBar />}

        {/* Main Content */}
        <main className="flex-grow">
          <Outlet /> {/* Renders the matched child route */}
        </main>

        {/* Footer */}
        {!isHomePage && <Footer />}
      </div>
    </AuthProvider>
  );
}

export default App;
