// src/components/Search/SearchAPI.js
import axios from 'axios';

//const base = 'http://172.161.87.201:8000'; // Ensure this matches your backend URL, 'http://localhost:8000, http://172.161.87.201:8000
// const base = 'http://gabors.net:8000';
const base = '/api';
//const base = 'http://localhost:8000'; // Ensure this matches your backend URL, 'http://localhost:8000, http://172.161.87.201:8000

export const searchDatabase = async (query) => {
    try {
        const response = await axios.get(`${base}/search/global`, {
            params: { query },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error.response?.data || new Error('Failed to search database');
    }
};

// New function for keyword search
export const keywordSearchDatabase = async (query) => {
    try {
        const response = await axios.get(`${base}/search/keyword`, { // assuming keyword endpoint
            params: { query },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error.response?.data || new Error('Failed to perform keyword search');
    }
};

export const getUserProfile = async (userId) => {
    try {
        const response = await fetch(`${base}/search/user/${userId}/profile`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        
        if (!response.ok) {
            throw new Error('Failed to fetch user profile');
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

export const downloadUserPDF = async (userId) => {
    try {
        const response = await fetch(`${base}/search/user/${userId}/download_pdf`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to download PDF');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank');
        if (newTab) {
            newTab.focus();
        } else {
            throw new Error('Failed to open new tab');
        }
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading PDF:', error);
        throw error;
    }
};

// Add the friend request function
export const handleSendFriendRequest = async (userId) => {
    try {
        const response = await fetch(`${base}/send_friend_request/${userId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.detail || 'Failed to send friend request');
        }

        const result = await response.json();
        return {
            success: true,
            message: result.message || 'Friend request sent successfully'
        };
    } catch (error) {
        console.error('Error sending friend request:', error);
        throw error;
    }
};

// You might also want these related functions
export const withdrawFriendRequest = async (userId) => {
    try {
        const response = await fetch(`${base}/delete_friend_request/${userId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to withdraw friend request');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error withdrawing friend request:', error);
        throw error;
    }
};

export const checkFriendshipStatus = async (userId) => {
    try {
        const response = await fetch(`${base}/check_friendship_status/${userId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to check friendship status');
        }

        return await response.json();
    } catch (error) {
        console.error('Error checking friendship status:', error);
        throw error;
    }
};
