import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import '../index.css';

const DontFuckUp = () => {
  const [showFirstPhrase, setShowFirstPhrase] = useState(true);
  const [showSecondPhrase, setShowSecondPhrase] = useState(false);
  const firstPhrase = 'this is gabors.net, yet to be finished...';
  const secondPhrase = "";
  const typewriterRef = useRef(null);
  const base = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <div className="">
    

<div className=" text-center   items-center justify-center">
    <div className="w-full">
    <button 
              onClick={() => navigate('/Login')} >
       <h2 className="text-base md:text-lg font-bold text-center typewriter-text text-black font-['EB Garamond']">
          {showFirstPhrase && (
            <Typewriter
              options={{
                autoStart: true,
                loop: false,
                delay: 50,
                deleteSpeed: 30,
                cursor: '|',
              }}
              onInit={(typewriter) => {
                typewriterRef.current = typewriter;
                typewriter
                  .typeString(firstPhrase)
                  .callFunction(() => {
                    setShowFirstPhrase(false);
                    setTimeout(() => {
                      setShowSecondPhrase(true);
                    }, 50);
                  })
                  .start();
              }}
            />
          )}
          {!showFirstPhrase && <span dangerouslySetInnerHTML={{ __html: firstPhrase }} />}
        </h2>
        </button>
      </div>

    </div>


        <div className={`flex items-center justify-center transition-opacity duration-1000`}>

            <button 
              onClick={() => navigate('/First')} 
              className={`text-black/30 text-sm py-1 px-2 rounded-full
               
                hover:underline transition-all duration-200 
                hover:cursor-pointer fade-in ${showButton ? 'visible' : ''}`}
            >
              Start here!
            </button>


          
          </div>
         
    </div>
    
  );
};

export default DontFuckUp;
