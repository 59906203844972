// src/components/Profile.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { FileText, ChevronDown } from 'lucide-react';
import { FaCheckCircle } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSearch, faTimes, faDollar, faEnvelope, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DummyImage } from './DummyImage'
import UserProfileModal from './Search/UserProfileModal'; // Import UserProfileModal

import { FaLinkedin, FaGithub, FaTwitter, FaExternalLinkSquareAlt } from 'react-icons/fa';
import { PiLinkFill } from "react-icons/pi";
import { MdEmail } from "react-icons/md";
import { FaFileAlt } from 'react-icons/fa';


import '../index.css';
import { 
    getProfile, 
    updateProfile, 
    downloadPDF, 
    updateBio, 
    openPDFInNewTab,
    checkPDF, 
    updateCustomFields, 
    deleteCustomField, 
    deletePDF,
    // Friend Management
    searchUsers,
    sendFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    removeFriend,
    deleteFriendRequest,
    getUsers,
    getFriendRequests,
    getFriendsList,
    getSentRequests,
    uploadPicture,
    deletePicture,
    getUserProfile, // Import getUserProfile
    downloadUserPDF // Import downloadUserPDF
} from './API';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaUserPlus } from 'react-icons/fa'; // Import the icon at the top of your file

const Profile = () => {
    const { user, setUser } = useContext(AuthContext);
    const [selectedFile, setSelectedFile] = useState(null);
    const [bio, setBio] = useState('');
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [messageOpacity, setMessageOpacity] = useState(0);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const [customFields, setCustomFields] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [uploadSuccessful, setUploadSuccessful] = useState(false);
    const [selectedPictureFile, setSelectedPictureFile] = useState(null);
    const [uploadPictureSuccessful, setUploadPictureSuccessful] = useState(false);

    const navigate = useNavigate();

    // Your Network States
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [friendsList, setFriendsList] = useState([]);
    const [incomingRequests, setIncomingRequests] = useState([]);
    const [outgoingRequests, setOutgoingRequests] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false); // State for search visibility

    const dropdownRef = useRef(null); // Create a ref for the dropdown

    // State for selected friend and modal visibility
    const [selectedFriend, setSelectedFriend] = useState(null);
    const [showFriendProfileModal, setShowFriendProfileModal] = useState(false);

    // Fetch profile data and initial friend data
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                console.log("Fetching profile data...");
                if (!user) {
                    const userData = await getProfile();
                    setUser(userData);
                    setBio(userData.bio || '');
                    setCustomFields(userData.custom_fields || []);
                } else {
                    setBio(user.bio || '');
                    setCustomFields(user.custom_fields || []);
                }

                const pdfCheck = await checkPDF();
                if (pdfCheck.has_pdf) {
                    setUploadedFileName(pdfCheck.pdf_filename);
                    setUploadSuccessful(true);
                }

                // Fetch friends list
                const friends = await getFriendsList();
                setFriendsList(friends);

                // Fetch incoming friend requests
                const incoming = await getFriendRequests();
                setIncomingRequests(incoming);
                setNotificationCount(incoming.length);

                // Fetch outgoing friend requests
                const outgoing = await getSentRequests();
                setOutgoingRequests(outgoing);

                console.log("Profile data fetched successfully.");
            } catch (error) {
                console.error('Error fetching profile data:', error);
                setMessage('Error fetching profile data.');
                navigate('/login');
            }
        };

        fetchProfileData();
    }, [user, setUser, navigate]);


    // Your Network Handlers

    const handleSearch = async () => {
        const trimmedSearchTerm = searchTerm.trim();
        try {
            console.log(`Searching for users with term: ${trimmedSearchTerm}`);
            const results = await searchUsers(trimmedSearchTerm);
            console.log('Search Results:', results);
            setSearchResults(results);
            setIsSearching(true);
        } catch (error) {
            console.error('Error searching for friends:', error);
            setMessage(error.message || 'Error searching for friends.');
            setShowMessage(true);
            setMessageOpacity(1);
            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };



    // Function to handle clicking on a friend to view their profile
    const handleFriendClick = async (friendId) => {
        try {
            const friendProfile = await getUserProfile(friendId);
            setSelectedFriend(friendProfile);
            setShowFriendProfileModal(true);
        } catch (error) {
            console.error('Error fetching friend profile:', error);
            setMessage('Error fetching friend profile.');
            setShowMessage(true);
            setMessageOpacity(1);
            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };



    const handleSendFriendRequest = async (userId) => {
        try {
            const responseMessage = await sendFriendRequest(userId);
            setMessage(responseMessage);
            setShowMessage(true);
            setMessageOpacity(1);

            // Update outgoing requests
            const user = searchResults.find(u => u.id === userId);
            setOutgoingRequests([...outgoingRequests, user]);

            // Update searchResults relationship
            setSearchResults(searchResults.map(userItem => {
                if (userItem.id === userId) {
                    return { ...userItem, relationship: 'request_sent' };
                }
                return userItem;
            }));

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Error sending friend request:', error);
            setMessage(error.message || 'Error sending friend request.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    const handleWithdrawFriendRequest = async (userId) => {
        try {
            const responseMessage = await deleteFriendRequest(userId);
            setMessage(responseMessage);
            setShowMessage(true);
            setMessageOpacity(1);

            // Update outgoing requests
            setOutgoingRequests(outgoingRequests.filter(user => user.id !== userId));

            // Update searchResults relationship
            setSearchResults(searchResults.map(userItem => {
                if (userItem.id === userId) {
                    return { ...userItem, relationship: 'none' };
                }
                return userItem;
            }));

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Error withdrawing friend request:', error);
            setMessage(error.message || 'Error withdrawing friend request.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    const handleAcceptFriendRequest = async (userId) => {
        try {
            const responseMessage = await acceptFriendRequest(userId);
            setMessage(responseMessage);
            setShowMessage(true);
            setMessageOpacity(1);

            // Update friends list
            const user = incomingRequests.find(u => u.id === userId);
            setFriendsList([...friendsList, user]);

            // Remove from incoming requests
            setIncomingRequests(incomingRequests.filter(user => user.id !== userId));
            setNotificationCount(notificationCount - 1);

            // Update searchResults relationship if present
            setSearchResults(searchResults.map(userItem => {
                if (userItem.id === userId) {
                    return { ...userItem, relationship: 'friend' };
                }
                return userItem;
            }));

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Error accepting friend request:', error);
            setMessage(error.message || 'Error accepting friend request.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    const handleDeclineFriendRequest = async (userId) => {
        try {
            const responseMessage = await declineFriendRequest(userId);
            setMessage(responseMessage);
            setShowMessage(true);
            setMessageOpacity(1);

            // Remove from incoming requests
            setIncomingRequests(incomingRequests.filter(user => user.id !== userId));
            setNotificationCount(notificationCount - 1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Error declining friend request:', error);
            setMessage(error.message || 'Error declining friend request.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    const handleRemoveFriend = async (userId) => {
        try {
            const responseMessage = await removeFriend(userId);
            setMessage(responseMessage);
            setShowMessage(true);
            setMessageOpacity(1);

            // Update friends list
            setFriendsList(friendsList.filter(friend => friend.id !== userId));

            // Update searchResults relationship if present
            setSearchResults(searchResults.map(userItem => {
                if (userItem.id === userId) {
                    return { ...userItem, relationship: 'none' };
                }
                return userItem;
            }));

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Error removing friend:', error);
            setMessage(error.message || 'Error removing friend.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    // File Upload Handlers

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            setUploadedFileName(file.name);
            handleFileUpload(file);
        } else {
            alert('Please upload a valid PDF file.');
        }
    };

    const handleFileUpload = async (file) => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('http://localhost:8000/upload_pdf', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            const result = await response.json();
            if (response.ok) {
                setUploadSuccessful(true);
                console.log("PDF uploaded successfully.");
            } else {
                console.error('Failed to upload PDF:', result);
                setMessage(result.detail || 'Failed to upload PDF.');
                setUploadSuccessful(false);
            }
        } catch (error) {
            console.error('Error uploading PDF:', error);
            setMessage('Error uploading PDF.');
            setUploadSuccessful(false);
        }
    };

    const handleDeletePDF = async () => {
        try {
            const result = await deletePDF();
            setUploadedFileName('');
            setUploadSuccessful(false);
        } catch (error) {
            setMessage(error.message || 'Failed to delete PDF.');
            console.error(error);
        }
    };

    const handleDeleteFile = () => {
        setSelectedFile(null);
        setUploadedFileName('');
        setUploadSuccessful(false);
        handleDeletePDF();
    };

    const handlePDFDownload = async () => {
        try {
            console.log("Initiating PDF download...");
            await downloadPDF();
            setMessage('PDF downloaded successfully.');
        } catch (error) {
            console.error('Error downloading PDF:', error);
            setMessage('Failed to download PDF.');
        }
    };

    const handlePDFOpen = async () => {
        try {
            console.log("Initiating PDF open...");
            await openPDFInNewTab();
            setMessage('PDF opened successfully.');
        } catch (error) {
            console.error('Error opening PDF:', error);
            setMessage('Failed to open PDF.');
        }
    };

    const handleBioUpdate = async () => {
        try {
            const result = await updateBio(bio);
            setMessage('Bio updated successfully.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Failed to update bio:', error);
            setMessage(error.message || 'Failed to update bio.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    // Custom Fields Handlers

    const handleAddField = () => {
        const title = dropdownValue === 'Custom' ? 'Enter title for your information' : dropdownValue;
        if (title === 'Custom') {
            alert('Please select a valid information type or choose a different option.');
            return;
        }
        setCustomFields([...customFields, { title, text: '', isPublic: true }]);
        setDropdownValue('');
    };

    const handleFieldChange = (index, key, value) => {
        const updatedFields = customFields.map((field, i) =>
            i === index ? { ...field, [key]: value } : field
        );
        setCustomFields(updatedFields);
    };

    const handleSaveCustomFields = async () => {
        try {
            await updateCustomFields(customFields);
            setMessage('Custom fields saved successfully.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Failed to update custom fields:', error);
            setMessage(error.message || 'Failed to save custom fields.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    const handleDeleteField = async (index, title) => {
        try {
            await deleteCustomField(title);
            setCustomFields(customFields.filter((_, i) => i !== index));
            setMessage('Custom field deleted successfully.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Failed to delete field:', error);
            setMessage(error.message || 'Failed to delete custom field.');
            setShowMessage(true);
            setMessageOpacity(1);

            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };


    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownSelect = (option) => {
        setDropdownValue(option);
        setIsDropdownOpen(false);
    };

    const toggleSearchVisibility = () => {
        setIsSearchVisible(!isSearchVisible); // Toggle the visibility of the search section
    };



    // Handle Picture File Change
    const handlePictureChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif')) {
            setSelectedPictureFile(file);
            handlePictureUpload(file);
        } else {
            alert('Please upload a valid image file (JPEG, PNG, GIF).');
        }
    };

    // Handle Picture Upload
    const handlePictureUpload = async (file) => {
        try {
            const result = await uploadPicture(file);
            setMessage(result.message || 'Picture uploaded successfully.');
            setShowMessage(true);
            setMessageOpacity(1);
            setUploadPictureSuccessful(true);

            // Update the user data by re-fetching profile
            const updatedUser = await getProfile();
            setUser({
                ...updatedUser,
                picture: `${updatedUser.picture}?t=${new Date().getTime()}` // Force refresh
            });

            setBio(updatedUser.bio || '');
            setCustomFields(updatedUser.custom_fields || []);
            setUploadedFileName(updatedUser.pdf_filename || '');
            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Error uploading picture:', error);
            setMessage(error.message || 'Error uploading picture.');
            setShowMessage(true);
            setMessageOpacity(1);
            setUploadPictureSuccessful(false);
            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    // Handle Picture Deletion
    const handleDeletePicture = async () => {
        try {
            // Attempt to delete the picture
            const result = await deletePicture();
            setMessage(result.message || 'Picture deleted successfully.');
            setShowMessage(true);
            setMessageOpacity(1);
            setUploadPictureSuccessful(false);
            setSelectedPictureFile(null); // Allow uploading another picture

            // Clear the file input value to allow re-upload
            const pictureInput = document.getElementById('pictureInput');
            if (pictureInput) {
                pictureInput.value = '';
            } else {
                console.warn('Picture input element not found.');
            }

            // Update the user data by re-fetching profile
            const updatedUser = await getProfile();
            setUser(updatedUser);
            setBio(updatedUser.bio || '');
            setCustomFields(updatedUser.custom_fields || []);
            setUploadedFileName(updatedUser.pdf_filename || '');
            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        } catch (error) {
            console.error('Error deleting picture:', error);
            setMessage(error.message || 'Error deleting picture.');
            setShowMessage(true);
            setMessageOpacity(1);
            setTimeout(() => {
                setMessageOpacity(0);
                setTimeout(() => setShowMessage(false), 500);
            }, 3000);
        }
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);





    return (
        <div className="mx-auto items-start w-full max-w-4xl px-4">
            <div className="container flex flex-col items-start h-auto min-h-screen">
                <div className="flex justify-center w-full">
                    <h1 className="text-4xl font-bold mb-6 text-center mt-20">Your Profile</h1>
                </div>


              



                {/* User Information Section */}
                <div className="rounded-lg  box p-6 mt-5 ">
                    <h2 className="text-2xl font-semibold mb-4">{user.username}</h2>

                      
                    {/* Profile Picture */}
                    <div className="flex flex-col md:flex-row items-start  gap-6">
                        <div className="relative">
                            {user.picture ? (
                                <img 
                                    src={user.picture} 
                                    alt="Profile Picture"
                                    className="w-40 h-40 rounded-lg object-cover" 
                                />
                            ) : (
                                <DummyImage shape="avatar" className="mb-4 w-40 h-40 rounded-lg" />
                            )}
                            <div className="absolute top-32 right-14 flex space-x-2">
                                <label 
                                    className="bg-white/50 text-black p-1 rounded-[4px] text-[10px] hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out cursor-pointer flex items-center justify-center"
                                    style={{ width: '20px', height: '20px', position: 'absolute', bottom: '-25px', right: '50px' }}
                                >
                                    <FontAwesomeIcon icon={faPen} className="m-auto" />
                                    <input 
                                        type="file" 
                                        accept="image/*" 
                                        onChange={handlePictureChange}
                                        className="hidden" 
                                    />
                                </label>
                                <button 
                                    onClick={handleDeletePicture}
                                    className="bg-white/50 text-black p-1 rounded-[4px] text-[10px] hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out flex items-center justify-center"
                                    style={{ width: '20px', height: '20px', position: 'absolute', bottom: '-25px', right: '78px' }}
                                >
                                    <FontAwesomeIcon icon={faTrash} className="m-auto" />
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col ">
                            <p className="text-base mb-2"><strong>Email:</strong> {user.email}</p>
                            <p className="text-base mb-2"><strong>Size of 1st Network:</strong> {user.firstnetworkSize || 'N/A'}</p>
                            <p className="text-base mb-2"><strong>Size of 2nd Network:</strong> {user.secondnetworkSize || 'N/A'}</p>
                            <p className="text-base text-black/20 mb-2"><strong>You're based in:</strong> {user.location || 'N/A'}</p>
                            <div className="flex flex-row space-x-2 text-black/20">
                                <FaLinkedin className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                <FaTwitter className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                <FaGithub className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                <PiLinkFill className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                <FaExternalLinkSquareAlt className="w-5 h-5 sm:w-6 sm:h-6 hover:scale-110 transition-transform" />
                                {/* <a onClick={handlePDFOpen} className="relative cursor-pointer flex flex-col items-center justify-center">
                                         <FaFileAlt className="w-6 h-6 text-black hover:scale-110 transition-transform" />
                                         <span className="relative text-black text-sm font-bold text-orange-400">CV</span>
                                     </a> */}
                            </div>
                             </div>
                             
                    </div>






                </div>

                {/* Bio Section */}
                <div className="rounded-lg box p-6 ">
                    <h2 className="text-2xl font-semibold ">Bio</h2>
                    <textarea
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        className="w-full rounded-lg bg-transparent pt-4 pb-2 hover:p-4 hover:mt-4 placeholder-gray-500 text-black resize-y hover:bg-white transition-all duration-300 ease-in-out focus:outline-none scrollbar-hide"
                        rows={3}
                        placeholder="Write something about yourself..."
                        style={{ minHeight: '200px', maxHeight: '300px', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                    />
                    <button 
                        onClick={async () => {
                            await handleBioUpdate();
                            const updatedUser = await getProfile();
                            setBio(updatedUser.bio || '');
                        }} 
                        className={`bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out mt-1`}
                    >
                        Update Bio
                    </button>

                    {/* {showMessage && (
                        <p
                            className={`text-green-500 mt-2 transition-opacity duration-500 ease-in-out`}
                            style={{ opacity: messageOpacity }}
                        >
                            {message}
                        </p>
                    )} */}
                </div>

                {/* CV Section */}
                <div className="rounded-lg box p-6">
                    <h2 className="text-2xl font-semibold mb-4">Your CV</h2>
                    <div className="flex flex-col items-start">
                        <label className="relative cursor-pointer">
                            <input
                                type="file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                className="hidden"
                            />
                            <div className="flex flex-col items-center gap-2 p-4 border-2 border-dashed border-gray-500 rounded-lg transition-transform transform hover:scale-105 hover:border-blue-500 hover:text-blue-500 transition-all duration-300 ease-in-out group">
                                <div className="flex items-center gap-2">
                                    <div className="relative">
                                        <FileText
                                            size={32}
                                            className="text-gray-900 group-hover:text-blue-500 transition-all duration-300 ease-in-out"
                                        />
                                        {uploadSuccessful && (
                                            <FaCheckCircle 
                                                size={20} 
                                                className="text-green-400 absolute" 
                                                style={{ bottom: '-6px', right: '-6px' }} 
                                            />
                                        )}
                                    </div>
                                </div>
                                {!uploadedFileName && (
                                    <span className="text-gray-600">Add your CV</span>
                                )}
                                {uploadedFileName && (
                                        <span className="text-gray-600">{uploadedFileName}</span>
                                    )}
                                {uploadedFileName && (
                                    <div className="flex space-x-2 mt-2">
                                        <button 
                                            onClick={handleDeleteFile} 
                                            className="bg-red-500/20 border-red-500 border-[1px] hover:bg-red-500/80 text-white text-sm px-3 py-1 rounded-lg hover:scale-105 transition-all duration-300 ease-in-out"
                                        >
                                            Delete File
                                        </button>
                                        <button 
                                            onClick={handlePDFOpen} 
                                            className="bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out"
                                        >
                                            Download PDF
                                        </button>
                                    </div>
                                )}
                            </div>
                        </label>
                      
                    </div>
                </div>

                {/* Custom Fields Section */}
                <div className="rounded-lg  p-6 box" ref={dropdownRef}> {/* Attach ref here */}
                    <h2 className="text-2xl font-semibold mb-4">Q&A</h2>
                    {customFields.map((field, index) => (
                        <div key={index} className="mt-6 w-full">
                            <div className="flex justify-between items-center">
                                <input
                                    type="text"
                                    value={field.title}
                                    onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                                    className="w-4/5 mb-2 pt-2 pb-2 hover:p-2 mr-2 rounded-lg bg-transparent font-bold text-black hover:bg-white transition-all duration-300 ease-in-out text-sm focus:outline-none"
                                />
                                <div className="flex space-x-2 mb-2">
                                    <div
                                        onClick={() => handleFieldChange(index, 'isPublic', !field.isPublic)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: field.isPublic ? 'flex-end' : 'flex-start',
                                            width: '90px',
                                            backgroundColor: field.isPublic ? '#22c55e' : '#ef4444',
                                            borderRadius: '30px',
                                            cursor: 'pointer',
                                            border: field.isPublic ? '2px solid #22c55e' : '2px solid #ef4444',
                                            position: 'relative',
                                            transition: 'all 0.3s ease-in-out',
                                        }}
                                        className="text-xs"
                                    >
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                color: 'black',
                                                padding: '0 10px',
                                                position: 'absolute',
                                                right: field.isPublic ? '25px' : 'auto',
                                                left: field.isPublic ? 'auto' : '25px',
                                            }}
                                        >
                                            {field.isPublic ? 'Public' : 'Hidden'}
                                        </span>

                                        <div
                                            style={{
                                                height: '30px',
                                                width: '30px',
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                transition: 'all 0.3s ease-in-out',
                                                transform: field.isPublic ? 'translateX(0px)' : 'translateX(0)',
                                            }}
                                        />
                                    </div>

                                    <button
                                        className="py-1 px-2 rounded-lg bg-red-500/20 border-red-500 border-[1px] hover:bg-red-500/80 hover:scale-105 transition-all duration-300 ease-in-out text-xs"
                                        onClick={() => handleDeleteField(index, field.title)} // Call delete function here
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <textarea
                                value={field.text}
                                onChange={(e) => handleFieldChange(index, 'text', e.target.value)}
                                className="w-full  rounded-lg bg-transparent pt-2 pb-2 hover:pr-2 hover:pl-4 text-black hover:bg-white transition-all duration-300 ease-in-out text-sm focus:outline-none"
                                rows={2}
                                placeholder="Enter details here..."
                            />

                            <div className="border-t border-gray-900/30 my-2"></div>


                            
                        </div>
                    ))}

                    {/* Dropdown & Add Button */}
                    <div className="relative">
                        <div 
                            className="p-2  bg-white/50 text-black rounded-lg cursor-pointer flex items-center justify-between hover:bg-white transition-all duration-300 ease-in-out"
                            onClick={handleToggleDropdown}
                        >
                            <span className="font-semibold px-2 truncate">{dropdownValue || 'Select Information Type'}</span>
                            <ChevronDown className={`w-4 h-4 transition-transform duration-300 ${isDropdownOpen ? 'rotate-180' : ''}`} />
                        </div>

                        {isDropdownOpen && (
                            <ul className="absolute left-0 w-full bg-white rounded-lg shadow-lg mt-2 max-h-60 overflow-y-auto z-20 text-xs">
                                {[
                                    "Custom",
                                    "List of 10 things you are looking (for help) with",
                                    "List of things you can offer other help with",
                                    "If we met a year from now, what would we be celebrating?",
                                    "What would you do if you wouldnt be afraid?",
                                    "What is the no or refusal you keep postponing?",
                                    "What commitments have made that you no longer belive in?",
                                    "What is the gift that you currently hold in exile?",
                                    "What is the crossroads at which I find myself?",
                                    "This is what I want to be known for",
                                    "This is what i want to become better at",
                                    "This is what I can help you emotionally with",
                                    "Your essay",
                                    "Books you have read",
                                    "Your explained CV",
                                    "List of things you want to have achieved in the next 6 months",
                                    "Where you have lived",
                                    "Your network of people",
                                    "Your fav philosopher",
                                    "Your fav book",
                                    "Your fav music artist",
                                    "Your fav artist",
                                    "Your fav visual artist",
                                    "Your fav movie",
                                    "Your hobbies",
                                    "Your lectures you have taken",
                                    "Coding experiences",
                                    "Languages you speak",
                                    "Languages you can code in and libraries you have used"
                                    
                                ].map((option, index) => (
                                    <li 
                                        key={index} 
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-200 transition-colors duration-200"
                                        onClick={() => handleDropdownSelect(option)}
                                    >
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="flex space-x-4 mt-4">
                        <button 
                            onClick={handleAddField} 
                            className="bg-white/50 text-black text-xs px-3 py-1 rounded-lg hover:bg-white hover:scale-105 transition-transform duration-300 ease-in-out"
                        >
                            Add Information
                        </button>
                        <button 
                            onClick={handleSaveCustomFields} 
                            className={`ml-2 bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out`}
                            >
                            Save Information
                        </button>
                    </div>
                </div>



                

                {/* Your Network Section */}
                <div className="rounded-lg p-6 box mb-20">
                    <h2 className="text-2xl font-semibold mb-4">Your Network</h2>

                    {/* Action Buttons */}
                    <div className="flex space-x-4 mb-4">
                        <button
                            type="button"
                            className={`ml-2 bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out`}
                            onClick={toggleSearchVisibility}
                        >
                            <FontAwesomeIcon icon={faUserPlus} className="mr-1" />
                            Add Friend
                        </button>

                        <button
                            type="button"
                            className="relative ml-2 bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out"
                            onClick={() => setIsNotificationsVisible(true)}
                        >
                            <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                            {notificationCount > 0 && (
                                <span className="absolute -top-1 -right-1 bg-red-600 text-white rounded-lg text-xs w-4 h-4 flex items-center justify-center">
                                    {notificationCount}
                                </span>
                            )}
                            Friend Requests
                        </button>
                    </div>






               
               {/* Inline Search for Friends */}
               {isSearchVisible && (
                        <div className="mb-4 bg-gray-500/50 p-4 rounded-lg">
                                <div className="flex items-center">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSearch();
                                            }
                                        }}
                                        placeholder="Enter friend's name..."
                                        className="w-full p-2 rounded-lg bg-white/70 text-black hover:bg-white transition-all duration-300 ease-in-out text-xs focus:outline-none"
                                    />





                                    <div className="flex ">

                                    <button
                                        onClick={handleSearch}
                                        className={`ml-2 bg-white/50 text-black p-2 rounded-lg text-xs hover:bg-white hover:text-black transition-all hover:scale-105 duration-300 ease-in-out`}
                                    >
                                        Search
                                    </button>


                                    <button
                                        onClick={() => {
                                            toggleSearchVisibility();
                                            setSearchResults([]);
                                            setSearchTerm('');
                                        }}
                                        className="ml-2 rounded-lg bg-red-500/20 border-red-500 border-[1px] hover:bg-red-500/80 hover:scale-105 transition-all duration-300 ease-in-out text-xs w-8 h-8 flex items-center justify-center"
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                    </div>



                                    

                                </div>

                            {isSearching && (
                                <div className="mt-4 max-h-96 min-h-20 overflow-y-auto rounded-lg "> {/* max-h-96 ~ 384px */}
                                    <h3 className="text-base font-bold font-semibold mb-1 mt-5">Search Results:</h3>
                                    {searchResults.length > 0 ? (
                                        <ul className="space-y-2">
                                            {searchResults.map((userItem, index) => (
                                                <li 
                                                    key={index} 
                                                    className="flex justify-between items-center p-2 bg-white/50 rounded-lg shadow-sm space-x-2 cursor-pointer hover:bg-white/60"
                                                    onClick={() => handleFriendClick(userItem.id)}
                                                >
                                                    <div className="flex items-center space-x-2">
                                                        {userItem.picture ? (
                                                            <img
                                                                src={userItem.picture}
                                                                alt={userItem.username}
                                                                className="w-6 h-6 rounded-lg object-cover"
                                                            />
                                                        ) : (
                                                            <DummyImage width={24} height={24} shape="avatar" className=" rounded-lg" />
                                                        )}
                                                        <div>
                                                            <p className="text-xs font-medium">{userItem.username}</p>
                                                            {/* <p className="text-xs text-gray-600">{userItem.email}</p> */}
                                                        </div>
                                                    </div>
                                                    <div onClick={(e) => e.stopPropagation()}>
                                                        {userItem.relationship === 'friend' ? (
                                                            <button
                                                                onClick={() => handleRemoveFriend(userItem.id)}
                                                                className="px-2 py-1 bg-red-500 text-white rounded-lg text-[9px] md:text-xs hover:bg-red-600 transition-colors duration-300"
                                                            >
                                                                Remove Friend
                                                            </button>
                                                        ) : userItem.relationship === 'request_sent' ? (
                                                            <button
                                                                onClick={() => handleWithdrawFriendRequest(userItem.id)}
                                                                className="px-2 py-1 bg-yellow-500/40 border-[1px] border-yellow-500 text-black rounded-lg text-[9px] md:text-xs  hover:bg-yellow-500 transition-colors duration-300"
                                                            >
                                                                Withdraw Request
                                                            </button>
                                                        ) : userItem.relationship === 'request_received' ? (
                                                            <div className="flex space-x-2">
                                                                <button
                                                                    onClick={() => handleAcceptFriendRequest(userItem.id)}
                                                                    className="px-2 py-1 bg-green-500 text-white rounded-lg text-[9px] md:text-xs  hover:bg-green-600 transition-colors duration-300"
                                                                >
                                                                    Accept
                                                                </button>
                                                                <button
                                                                    onClick={() => handleDeclineFriendRequest(userItem.id)}
                                                                    className="px-2 py-1 bg-red-500 text-white rounded-lg text-[9px] md:text-xs  hover:bg-red-600 transition-colors duration-300"
                                                                >
                                                                    Decline
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                onClick={() => handleSendFriendRequest(userItem.id)}
                                                                className="px-2 py-1 bg-green-500 text-white rounded-lg text-[10px] md:text-sm  hover:bg-green-600 transition-colors duration-300 flex items-center justify-center"
                                                            >
                                                                <FaUserPlus className="mr-1 text-[16px] md:text-base" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className="text-xs text-black">No users found.</p>
                                    )}
                                </div>
                                )}
                            </div>
                        )}






                    {/* Friends List as a Table */}
                    <div className="mt-4">
                        <h3 className="text-sm font-semibold mb-2">Your Friends</h3>
                        {friendsList.length > 0 ? (
                            <div className="overflow-hidden rounded-lg bg-white/35"> {/* Low opacity white background and rounded corners */}
                                <table className="w-full text-left table-auto">
                                    <thead>
                                        <tr className="bg-white/70 text-black  text-xs  md:text-base">
                                            <th className="px-4 py-2">Username</th>
                                            <th className="px-4 py-2 text-center">Network Size</th>
                                            <th className="px-4 py-2 text-right pr-7">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {friendsList.map(friend => (
                                            <tr 
                                                key={friend.id} 
                                                className="hover:bg-white/70 cursor-pointer transition-all duration-300 ease-in-out"
                                                onClick={() => handleFriendClick(friend.id)} // Click handler
                                            >
                                                <td className="px-4 py-2 flex items-center space-x-2">
                                                    {friend.picture ? (
                                                        <img
                                                            src={friend.picture}
                                                            alt={friend.username}
                                                            className="w-8 h-8 rounded-lg object-cover"
                                                        />
                                                    ) : (
                                                        <DummyImage width={32} height={32} shape="avatar" className="rounded-lg" />
                                                    )}
                                                    <span className="text-sm">{friend.username}</span>
                                                </td>
                                                <td className="px-4 py-2 text-sm text-black text-center">{friend.firstnetworkSize}</td>
                                                <td className="px-4 py-2 text-right pr-6">
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent triggering row click
                                                            handleRemoveFriend(friend.id);
                                                        }}
                                                        className="px-2 py-1  text-[9px] md:text-xs rounded-lg bg-red-500/20 border-red-500 border-[1px] hover:bg-red-500/80 hover:scale-105 transition-all duration-300 ease-in-out "
                                                    >
                                                        Remove
                                                    </button>
                                                </td> 
                                            </tr>

                                        ))}
                                        
                                    </tbody>
                                    
                                </table>
                            </div>
                        ) : (
                            <p className="text-xs text-gray-500">You have no friends yet.</p>
                        )}
                    </div>





                    {/* Outgoing Friend Requests */}
                    <div className="mt-4">
                        <h3 className="text-sm font-semibold mb-2">Outgoing Friend Requests</h3>
                        {outgoingRequests.length > 0 ? (
                            <ul className="space-y-2">
                                {outgoingRequests.map(request => (
                                    <li key={request.id} className="flex justify-between items-center p-2 bg-white/50 rounded-lg shadow-sm">
                                        <div className="flex items-center space-x-2">
                                            
                                            
                                             {request.picture ? (
                                                <img
                                                    src={request.picture}
                                                    alt={request.username}
                                                    className="w-8 h-8 rounded-lg object-cover"
                                                />
                                            ) : (
                                                <DummyImage width={32} height={32} shape="avatar" className="rounded-lg" />
                                            )}
                                            
                                                        
                                                        
                                            <div>
                                                <p className="text-xs font-medium">{request.username}</p>
                                                <p className="text-xs text-gray-600">{request.email}</p>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => handleWithdrawFriendRequest(request.id)}
                                            className="px-2 py-1 bg-yellow-500/40 border-[1px] border-yellow-500 text-black rounded-lg text-[9px] md:text-xs  hover:bg-yellow-500 transition-colors duration-300"
                                            >
                                            Withdraw Request
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-xs text-gray-500">No outgoing friend requests.</p>
                        )}
                    </div>
                </div>

                {/* Notifications Modal */}
                {isNotificationsVisible && (
                    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                        <div className="w-[75%] max-w-4xl bg-[rgb(240,234,219)] rounded-lg shadow-lg overflow-hidden px-6 py-4 relative">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">Friend Requests</h3>
                                <button
                                    type="button"
                                    className="px-3 py-1 bg-white/70 text-black rounded-lg text-xs hover:bg-white transition-transform duration-300 ease-in-out"
                                    onClick={() => setIsNotificationsVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} className="mr-1" />
                                    Close
                                </button>
                            </div>
                            <div className="space-y-4">
                                {incomingRequests.length > 0 ? (
                                    incomingRequests.map((request, index) => (
                                        <div key={index} className="flex justify-between items-center p-2 bg-gray-200 rounded-lg space-x-2">
                                            <div className="flex items-center space-x-2">
                                            {request.picture ? (
                                                            <img
                                                                src={request.picture}
                                                                alt={request.username}
                                                                className="w-8 h-8 rounded-lg object-cover"
                                                            />
                                                        ) : (
                                                            <DummyImage width={32} height={32} shape="avatar" className="rounded-lg" />
                                                        )}
                                                <div>
                                                    <p className="text-sm font-medium">{request.username}</p>
                                                    <p className="text-xs text-gray-600">{request.email}</p>
                                                </div>
                                            </div>
                                            <div className="flex space-x-1">
                                                <button
                                                    type="button"
                                                    className="px-2 py-1 bg-green-500 text-white rounded-lg text-xs hover:bg-green-600 transition-colors duration-300"
                                                    onClick={() => handleAcceptFriendRequest(request.id)}
                                                >
                                                    Accept
                                                </button>
                                                <button
                                                    type="button"
                                                    className="px-2 py-1 bg-red-500 text-white rounded-lg text-xs hover:bg-red-600 transition-colors duration-300"
                                                    onClick={() => handleDeclineFriendRequest(request.id)}
                                                >
                                                    Decline
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-sm text-gray-600">No new friend requests.</p>
                                )}
                            </div>

                        
                        </div>
                    </div>
                )}
            </div>


              {/* Friend Profile Modal */}
              {showFriendProfileModal && selectedFriend && (
                <UserProfileModal 
                    user={selectedFriend}
                    onClose={() => {
                        setShowFriendProfileModal(false);
                        setSelectedFriend(null);
                    }}
                />
            )}


            {/* Message Toast */}
            {showMessage && (
                <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg transition-opacity duration-500 ease-in-out" style={{ opacity: messageOpacity }}>
                    {message}
                </div>
            )}
        </div>
    );

};

export default Profile;



